export const SystemErrorPage = () => {
  return (
    <main className="tw-flex tw-h-[100dvh] tw-w-full tw-flex-col tw-items-center tw-justify-center tw-bg-gray-50">
      <h2 className="tw-text-ui20 tw-font-semibold tw-text-gray-700">
        予期せぬエラーが発生しました
      </h2>
      <p className="tw-mt-4 tw-text-ui16 tw-text-gray-700">
        システム管理者にお知らせください。
      </p>
    </main>
  );
};
