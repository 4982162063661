import {
  Links,
  Meta,
  Outlet,
  Scripts,
  useRouteError,
  isRouteErrorResponse,
  ScrollRestoration,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { ReactNode } from 'react';

import './styles/globals.css';
import { SITE } from '~/constants/site';

import { NotFoundPage } from './components/pages/error/NotFound';
import { SystemErrorPage } from './components/pages/error/SystemError';
import { AppProvider } from './providers/app';

export const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <html lang="ja">
      <head>
        <title>{SITE.TITLE}</title>
        <link
          rel="icon"
          href={SITE.FAVICON_SRC}
          type="image/svg+xml"
          sizes="48x48"
        />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <Scripts />
        <ScrollRestoration />
      </body>
    </html>
  );
};

export const ErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFoundPage />;
  }

  captureRemixErrorBoundaryError(error);
  return <SystemErrorPage />;
};

export default function App() {
  return (
    <AppProvider>
      <Outlet />
    </AppProvider>
  );
}

export function HydrateFallback() {
  return <p>Loading...</p>;
}
