import { useNavigate } from '@remix-run/react';

import { CommonLayout } from '~/components/layouts/CommonLayout';
import { TITLEBAR_HEIGHT } from '~/components/layouts/CommonLayout/const';
import { Button } from '~/components/ui/button/Button';

export const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <CommonLayout>
      <div
        className="tw-flex tw-h-full tw-items-center tw-justify-center tw-bg-gray-50"
        style={{ paddingBottom: `${TITLEBAR_HEIGHT}px` }}
      >
        <div className="tw-text-center tw-text-gray-700">
          <p className="tw-text-ui20 tw-font-semibold">
            お探しのページが
            <br />
            見つかりませんでした。
          </p>
          <p className="tw-mt-4 tw-text-ui16 tw-font-light">
            お探しのページは、一時的に利用できないか、
            <br />
            変更・削除されている可能性があります。
          </p>
          <div className="tw-mt-10">
            <Button onClick={handleClick}>前のページに戻る</Button>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};
